@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
/* @import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css'; */
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
/* @import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css'; */
@import '../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

.itemContainer {
    padding: 10px;
    /* box-shadow: 1px 1px 6px gray; */
    margin-bottom: 3px;
    /* border-radius: 5px; */
}

.itemBody {
    box-shadow: 1px 1px 6px gray;
    border: 1px solid #191919;
}

.itemHeader {
    display: flex;
    justify-content: space-around;
    box-shadow: 1px 1px 6px gray;
    background-color: #191919;
    color: white;
}

.barcodeCard {
    display: flex;
    justify-content: space-around;
    box-shadow: 1px 1px 6px gray;
    margin: 7px 0;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    width: 40%;

}

.barcodeListHeader {
    display: flex;
    justify-content: space-around;
    background-color: #5ab25e;
    color: white;
    border-radius: 5px;
    margin: 5px;
}

.barcodeListContainer {
    box-shadow: 1px 1px 4px gray;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    background-color: #c5c1c1;
}

.modal.show .modal-dialog {
    margin-top: 110px;
}