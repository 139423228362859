@import "../../../node_modules/@syncfusion/ej2-inplace-editor/styles/tailwind.css";

.form-controle-container {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
}

td {
    padding: 10px;
    margin: 5px;
}